ul{
	margin: 0;
	padding: 0;
	list-style: none;
	li{
		position: relative;
		display: flex;
		align-items: flex-start;
		&:not(:nth-of-type(1)){
			margin-top: 15px;
		}
		svg{
			flex-shrink: 0;
			margin-top: 5px;
			margin-right: 15px;
		}
		&:before{
			content: '';
			flex-shrink: 0;
			width: 12px;
			height: 12px;
			margin-top: 3px;
			margin-right: 15px;
			background: url('/img/icon-arrow-right.svg');
		}
	}
}

.table{
	border: 1px solid #D9D9D9;
	border-radius: 10px;
	overflow: hidden;
	@media(max-width: 768px){
		overflow-x: auto;
	}
	table{
		width: 100%;
		border-collapse: collapse;
		transform: scale(1.01);
		@media(max-width: 768px){
			width: 600px;
		}
	}
	td{
		padding: 15px 25px;
		border: 1px solid #D9D9D9;
		text-align: center;
		@media(max-width: 768px){
			padding: 10px;
		}
		&.align-left{
			text-align: left;
		}
	}
	th{
		padding: 25px 15px;
		border: 1px solid #D9D9D9;
	}
}

.button{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding: 0 38px;
	font-size: 16px;
	font-weight: 700;
	color: #FFF;
	background: var(--red);
	box-shadow: 0px 4px 10px 0px rgba(233, 37, 76, 0.60);
	border-radius: 10px;
	transition: all .3s;
	&:hover{
		box-shadow: none;
	}
}

.title{
	font-size: 30px;
	font-weight: 300;
	@media(max-width: 565px){
		font-size: 22px;
	}
}



