@charset "UTF-8";
/*
0. Шрифты
1. Общие стили для всего сайта
2. Вспомогательные классы
3. Стили для одинаковых элементов
4. Шапка
5. Подвал
6. Стили которые не повторяются на других страницах

Мобильная версия начинается с 992пх

*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
:root {
  --grey: #242223;
  --blue: #2A688F;
  --lightBlue: #EDF7FF;
  --red: #E9254C;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
ШРИФТЫ 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	ОБЩИЕ СТИЛИ ДЛЯ ВСЕГО САЙТА 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
*,
p,
h1, h2, h3, h4, h5, h6 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  overflow-x: hidden;
}
html.fixed {
  overflow: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  color: #000;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  outline: none !important;
}
a:hover {
  text-decoration: none;
}

button {
  display: block;
  font-family: "Open Sans", sans-serif;
  background: transparent;
  border: none;
  outline: none !important;
  cursor: pointer;
}
button:focus {
  outline: none;
}
button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

input, textarea {
  outline: none !important;
}
input:focus-visible, textarea:focus-visible {
  outline: none;
}
input:disabled, textarea:disabled {
  pointer-events: none;
  opacity: 0.5;
}

b {
  font-weight: 700;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.compensate-for-scrollbar {
  padding-right: 0 !important;
}

* ::-webkit-scrollbar {
  width: 6px; /* ширина всей полосы прокрутки */
}
* ::-webkit-scrollbar-track {
  background: #FFF; /* цвет зоны отслеживания */
  border-radius: 20px;
}
* ::-webkit-scrollbar-thumb {
  background-color: var(--red); /* цвет бегунка */
  border-radius: 20px; /* округлось бегунка */
  border: 3px solid transparent;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//END ОБЩИЕ СТИЛИ ДЛЯ ВСЕГО САЙТА 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	ВСПОМОГАТЕЛЬНЫЕ КЛАССЫ (нужны там где нет нужды в осмысленном класснейминге)
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.color-blue {
  color: var(--blue);
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//END	ВСПОМОГАТЕЛЬНЫЕ КЛАССЫ 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	СТИЛИ ДЛЯ ОДИНАКОВЫХ ЭЛЕМЕНТОВ 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  position: relative;
  display: flex;
  align-items: flex-start;
}
ul li:not(:nth-of-type(1)) {
  margin-top: 15px;
}
ul li svg {
  flex-shrink: 0;
  margin-top: 5px;
  margin-right: 15px;
}
ul li:before {
  content: "";
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-top: 3px;
  margin-right: 15px;
  background: url("/img/icon-arrow-right.svg");
}

.table {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .table {
    overflow-x: auto;
  }
}
.table table {
  width: 100%;
  border-collapse: collapse;
  transform: scale(1.01);
}
@media (max-width: 768px) {
  .table table {
    width: 600px;
  }
}
.table td {
  padding: 15px 25px;
  border: 1px solid #D9D9D9;
  text-align: center;
}
@media (max-width: 768px) {
  .table td {
    padding: 10px;
  }
}
.table td.align-left {
  text-align: left;
}
.table th {
  padding: 25px 15px;
  border: 1px solid #D9D9D9;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 38px;
  font-size: 16px;
  font-weight: 700;
  color: #FFF;
  background: var(--red);
  box-shadow: 0px 4px 10px 0px rgba(233, 37, 76, 0.6);
  border-radius: 10px;
  transition: all 0.3s;
}
.button:hover {
  box-shadow: none;
}

.title {
  font-size: 30px;
  font-weight: 300;
}
@media (max-width: 565px) {
  .title {
    font-size: 22px;
  }
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//END СТИЛИ ДЛЯ ОДИНАКОВЫХ ЭЛЕМЕНТОВ 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
	HEADER 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 800px) {
  .header {
    position: fixed;
  }
  .header.is-scroll {
    background: #FFF;
  }
}
.header__inner {
  padding: 8px 0;
}
.header__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-connect {
  display: flex;
  align-items: center;
}
.header-connect__text {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  font-size: 10px;
}
.header-connect__social {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-email {
  display: flex;
  flex-direction: column;
}
.header-email__name {
  font-size: 10px;
}
.header-email__adress {
  color: #000;
  font-weight: 700;
}
.header-phone {
  display: flex;
  flex-direction: column;
}
.header-phone__name {
  font-size: 10px;
}
.header-phone__number {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
@media (max-width: 800px) {
  .header__connect {
    display: none;
  }
}
.header__email {
  text-align: center;
}
@media (max-width: 800px) {
  .header__email {
    display: none;
  }
}
.header__phone {
  text-align: center;
}
@media (max-width: 800px) {
  .header__phone {
    margin-left: auto;
    margin-right: 40px;
  }
}
@media (max-width: 565px) {
  .header__phone {
    display: none;
  }
}
.header__burger {
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: var(--red);
  box-shadow: 0px 4px 10px 0px rgba(233, 37, 76, 0.6);
}
@media (max-width: 800px) {
  .header__burger {
    display: flex;
  }
}
@media (max-width: 565px) {
  .header__burger {
    width: 40px;
    height: 40px;
  }
}

.logo {
  display: flex;
  align-items: center;
}
.logo__img {
  margin-right: 10px;
}
@media (max-width: 565px) {
  .logo__img {
    width: 44px;
  }
}
.logo__text {
  display: flex;
  flex-direction: column;
}
.logo__name {
  font-size: 15px;
}
@media (max-width: 565px) {
  .logo__name {
    font-size: 10px;
  }
}
.logo__name--white {
  color: #FFF;
}
.logo__info {
  font-size: 10px;
}
.logo__info--white {
  color: #FFF;
}

.social__link {
  display: flex;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 23px;
  cursor: pointer;
  overflow: hidden;
}
@media (max-width: 565px) {
  .burger {
    width: 24px;
    height: 18px;
  }
}
.burger span {
  width: 100%;
  height: 3px;
  background: #FFF;
  transition: all 0.5s;
}
@media (max-width: 565px) {
  .burger span {
    height: 2px;
  }
}
.burger.is-open span:nth-child(1) {
  transform: rotate(45deg) translateY(9px) translateX(5px);
  width: 35px;
}
.burger.is-open span:nth-child(2) {
  transform: translateX(-100%);
}
.burger.is-open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-9px) translateX(5px);
  width: 35px;
}

.nav {
  position: relative;
  z-index: 2;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  background: #FFF;
  border-radius: 10px;
  transition: all 0.3s;
}
@media (min-width: 800px) {
  .nav.is-scroll {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .nav {
    border-radius: 0;
  }
}
@media (max-width: 800px) {
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    background: rgba(7, 17, 23, 0.9);
  }
  .nav.is-open {
    right: 0;
  }
}
@media (max-width: 800px) {
  .nav__menu {
    width: 320px;
    height: 100%;
    padding: 20px;
    background: #FFF;
    overflow: auto;
  }
}
.nav__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 800px) {
  .nav__list {
    flex-direction: column;
    align-items: flex-start;
  }
}
.nav__item {
  margin: 0 !important;
}
.nav__item:before {
  display: none;
}
.nav__link {
  display: block;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  transition: all 0.3s;
}
@media (max-width: 800px) {
  .nav__link {
    padding: 10px 0;
  }
}
.nav__link:hover {
  color: var(--red);
}
@media (max-width: 800px) {
  .nav__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
}
.nav-logo {
  display: none;
  align-items: center;
}
@media (max-width: 800px) {
  .nav-logo {
    display: flex;
  }
}
.nav-logo__img {
  width: 34px;
  margin-right: 5px;
}
.nav-logo__text {
  display: flex;
  flex-direction: column;
}
.nav-logo__name {
  font-size: 8px;
}
.nav-logo__info {
  font-size: 7px;
}
.nav__close {
  display: none;
}
@media (max-width: 800px) {
  .nav__close {
    display: block;
  }
}
.nav__phone {
  display: none;
  margin-top: 25px;
}
@media (max-width: 800px) {
  .nav__phone {
    display: flex;
  }
}
.nav__email {
  display: none;
  margin-top: 25px;
}
@media (max-width: 800px) {
  .nav__email {
    display: flex;
  }
}
.nav__connect {
  display: none;
  margin-top: 25px;
}
@media (max-width: 800px) {
  .nav__connect {
    display: flex;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
	//END HEADER  
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
	FOOTER  
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.footer {
  padding: 18px 0;
  background: #071117;
}
.footer__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 850px) {
  .footer__box {
    flex-direction: column;
    gap: 50px;
  }
}
.footer__links {
  display: flex;
  gap: 30px;
}
@media (max-width: 565px) {
  .footer__links {
    flex-direction: column;
    gap: 20px;
  }
}
.footer__links a {
  font-size: 10px;
  color: #FFF;
  text-decoration: underline;
}

.dev {
  display: flex;
  align-items: center;
}
.dev__text {
  margin-right: 10px;
  font-size: 12px;
  color: #FFF;
}
.dev__logo {
  margin-top: 5px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
	//END FOOTER  
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
	СТИЛИ КОТОРЫЕ НЕ ПОВТОРЯЮТСЯ НА ДРУГИХ СТРАНИЦАХ 
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.home {
  position: relative;
  z-index: 1;
  padding-top: 255px;
  padding-bottom: 200px;
  background-image: url("../img/home-bg.webp");
  background-position: top left;
  background-size: cover;
}
@media (max-width: 992px) {
  .home {
    background-position: top center;
  }
}
@media (max-width: 768px) {
  .home {
    padding-top: 135px;
    padding-bottom: 105px;
  }
}
@media (max-width: 565px) {
  .home {
    padding-top: 90px;
    padding-bottom: 45px;
  }
}
.home__title {
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home__title {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .home__title {
    font-size: 24px;
  }
}
.home__tagtitle {
  margin-top: 20px;
  font-size: 20px;
}
@media (max-width: 768px) {
  .home__tagtitle {
    font-size: 16px;
  }
}
@media (max-width: 565px) {
  .home__tagtitle {
    font-size: 14px;
  }
  .home__tagtitle br {
    display: none;
  }
}
.home__advantages {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 50px;
}
@media (max-width: 565px) {
  .home__advantages {
    gap: 20px;
    margin-top: 20px;
  }
}
.home-advantage {
  display: flex;
  align-items: center;
}
.home-advantage__icon {
  margin-right: 10px;
}
.home-record {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 565px) {
  .home-record {
    flex-direction: column;
  }
}
.home-record__button {
  margin-right: 52px;
}
@media (max-width: 565px) {
  .home-record__button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.home-record__text {
  position: relative;
  font-size: 10px;
  text-align: center;
}
@media (max-width: 565px) {
  .home-record__text {
    margin-left: auto;
  }
}
.home-record__text::before {
  content: "";
  position: absolute;
  left: -57px;
  width: 100px;
  height: 100px;
  background: url("/img/icon-figure-arrow.svg") center center;
}
@media (max-width: 565px) {
  .home-record__text::before {
    left: -100px;
    top: -38px;
    background: url("/img/icon-figure-arrow-mobile.svg") center center;
  }
}

.gradient {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
}

.advantage {
  position: relative;
  padding: 15px;
  border-radius: 10px;
  background: #FFF;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
@media (max-width: 565px) {
  .advantage {
    padding-right: 80px;
  }
}
.advantage__title {
  font-size: 17px;
}
@media (max-width: 768px) {
  .advantage__title {
    font-size: 15px;
  }
}
@media (max-width: 565px) {
  .advantage__title {
    font-size: 14px;
  }
}
.advantage__text {
  margin-top: 9px;
  padding-right: 108px;
  font-size: 14px;
}
@media (max-width: 565px) {
  .advantage__text {
    padding-right: 0;
    font-size: 12px;
  }
}
.advantage__img {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
}
.advantage:nth-of-type(1) {
  grid-area: first;
}
.advantage:nth-of-type(2) {
  grid-area: two;
}
.advantage:nth-of-type(3) {
  grid-area: three;
}
.advantage:nth-of-type(4) {
  grid-area: four;
}
.advantage:nth-of-type(5) {
  grid-area: five;
}

.advantages {
  padding-top: 50px;
  padding-bottom: 100px;
}
.advantages__box {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "first first two two three three" ". four four five five .";
  gap: 30px;
}
@media (max-width: 960px) {
  .advantages__box {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "first first two two" "three three four four" ". five five .";
  }
}
@media (max-width: 565px) {
  .advantages__box {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "first" "two" "three" "four" "five";
  }
}

.groups {
  padding-bottom: 100px;
}
.groups__title {
  margin-bottom: 25px;
  text-align: center;
}
.preview {
  display: flex;
  flex-direction: column;
  height: auto;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.preview__imgbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  padding: 30px 0;
}
.preview__img--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.preview__info {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
}
.preview__title {
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s;
}
.preview__text {
  margin-top: 12px;
}
.preview__text--location {
  margin-bottom: 65px;
}
.preview__list {
  margin-top: 15px;
  margin-bottom: 40px;
}
.preview__morebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  margin-top: auto;
  font-size: 16px;
  font-weight: 700;
  color: var(--red);
  border-radius: 10px;
  border: 1px solid var(--red);
}
.preview__record {
  width: 100%;
  margin-top: 15px;
}
.preview:hover .preview__title {
  color: var(--blue);
}

.swiper {
  overflow: visible;
}
.swiper .swiper-slide {
  opacity: 0;
  transition: 0.5s;
}
.swiper .swiper-slide-active,
.swiper .swiper-slide-active + .swiper-slide,
.swiper .swiper-slide-active + .swiper-slide + .swiper-slide {
  opacity: 1;
}
.swiper .swiper-pagination {
  position: static;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.swiper .swiper-pagination-bullet {
  background: var(--blue);
  transition: all 0.3s;
}
.swiper .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  transform: scale(1.5);
}
.swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 2px);
}

.record {
  position: relative;
  z-index: 1;
  padding-top: 200px;
  background-image: url("/img/record-bg-1.webp");
  background-position: left top;
  background-size: cover;
}
.record__box {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 740px) {
  .record__box {
    flex-direction: column;
  }
}
.record__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.record__info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 36px;
  font-size: 16px;
  font-weight: 700;
  color: var(--blue);
  background: #FFF;
  border-radius: 10px;
}
.record__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 55px;
  color: #FFF;
}
@media (max-width: 740px) {
  .record__title {
    font-size: 30px;
    line-height: 35px;
  }
}
.record__tagtitle {
  position: relative;
  margin-top: 40px;
  font-size: 20px;
}
@media (max-width: 740px) {
  .record__tagtitle {
    font-size: 14px;
  }
}
.record__play {
  position: absolute;
  right: -190px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 740px) {
  .record__play {
    top: 155%;
  }
}
.record__form {
  width: 370px;
}
@media (max-width: 740px) {
  .record__form {
    width: 100%;
    margin-top: 170px;
  }
}
@media (max-width: 740px) {
  .record__form--2 {
    margin-top: 40px;
  }
}
.record__list {
  max-width: 400px;
  width: 100%;
  margin-top: 25px;
}

.record2 {
  padding-bottom: 70px;
  padding-top: 120px;
  background-image: url("/img/record-bg-2.webp");
  background-position: left top;
  background-size: cover;
}
@media (max-width: 565px) {
  .record2 {
    padding-top: 45px;
  }
}
.record2__title {
  font-size: 30px;
  font-weight: 300;
  line-height: 35px;
}
.record2__title--blue {
  color: var(--blue);
}

.video {
  max-width: 700px;
  width: 100%;
  padding: 0;
}
.video__box {
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
}
.video__box iframe {
  width: 100%;
  height: 100%;
}

.fancybox__content {
  background: transparent;
  color: initial;
}

.fancybox__content .carousel__button.is-close {
  top: -17px;
  right: -17px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: var(--blue);
}
@media (max-width: 768px) {
  .fancybox__content .carousel__button.is-close {
    right: 0;
  }
}

.form {
  padding: 30px 20px 20px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
}
.form__item:not(:nth-of-type(1)) {
  margin-top: 20px;
}
.form__fieldname {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(36, 34, 35, 0.5);
}
.form__choose {
  display: flex;
  gap: 22px;
  margin-top: 10px;
}
@media (max-width: 740px) {
  .form__choose {
    gap: 10px;
  }
}
.form__label {
  width: 100%;
  cursor: pointer;
}
.form__val {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  background: var(--lightBlue);
  border-radius: 10px;
}
.form__check {
  display: none;
}
.form__check:checked + .form__val {
  color: #FFF;
  background: var(--red);
  box-shadow: 0px 4px 10px 0px rgba(233, 37, 76, 0.6);
}
.form__fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.form__input {
  width: 100%;
  height: 60px;
  padding-left: 15px;
  font-weight: 700;
  border-radius: 10px;
  background: var(--lightBlue);
  border: none;
  outline: none !important;
}
.form__input::placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.form__input.error {
  border: 1px solid var(--red);
  color: var(--red);
}

.agree {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.agree__wrap {
  display: flex;
}
.agree__checkbox {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 9px;
  height: 9px;
  margin: 3px 3px 0 0;
  border-radius: 50%;
  border: 1px solid var(--red);
}
.agree__text {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}
.agree__text a {
  color: var(--red);
  text-decoration: underline;
}
.agree__text a:hover {
  text-decoration: none;
}
.agree__input {
  display: none;
}
.agree__input:checked + .agree__checkbox:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--red);
}

.gallery {
  padding-top: 100px;
  padding-bottom: 75px;
}
.gallery__title {
  margin-bottom: 15px;
  text-align: center;
}
.gallery__box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: row dense;
  gap: 30px;
}
@media (max-width: 768px) {
  .gallery__box {
    gap: 20px;
  }
}
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}
.gallery__item {
  border-radius: 10px;
  overflow: hidden;
  grid-row: span 7;
}
.gallery__item--small {
  grid-row: span 4;
}
.gallery__item:hover .gallery__img {
  transform: scale(1.1);
}

.target {
  padding-top: 50px;
  padding-bottom: 130px;
  background-image: url("/img/target-bg.webp");
  background-position: left top;
  background-size: cover;
}
.target__box {
  max-width: 670px;
  width: 100%;
}
.target__text {
  margin-top: 20px;
}
.target__list {
  max-width: 470px;
  width: 100%;
  margin-top: 15px;
}
.target__button {
  width: 270px;
  margin-top: 40px;
}
@media (max-width: 565px) {
  .target__button {
    width: 100%;
  }
}

.coach {
  padding-top: 100px;
  padding-bottom: 110px;
}
@media (max-width: 565px) {
  .coach {
    padding-top: 0;
  }
}
.coach__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 20px;
}
@media (max-width: 900px) {
  .coach__box {
    grid-template-columns: 1fr;
  }
}
.coach__item {
  display: flex;
  height: 202px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
@media (max-width: 565px) {
  .coach__item {
    height: auto;
  }
}
.coach picture {
  margin-left: auto;
}
.coach__info {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 25px;
}
@media (max-width: 565px) {
  .coach__info {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 0;
    padding-left: 15px;
  }
}
.coach__name {
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 565px) {
  .coach__name {
    font-size: 16px;
  }
}
.coach__status {
  margin-top: 10px;
  font-weight: 700;
}
.coach__text {
  margin-top: 5px;
}

.reviews {
  padding-bottom: 100px;
}
.reviews__box {
  margin-top: 25px;
}
.reviews__button {
  width: 270px;
  margin: 40px auto 0;
}

.review {
  position: relative;
  height: 245px;
}
@media (max-width: 565px) {
  .review {
    height: auto;
  }
}
.review__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 565px) {
  .review__inner {
    position: static;
  }
}
.review__headbox {
  display: flex;
  gap: 15px;
}
.review__avabox {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.review__foto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review__namebox {
  display: flex;
  flex-direction: column;
}
.review__author {
  color: var(--grey);
}
.review__status {
  margin-top: 3px;
  font-size: 18px;
  font-weight: 700;
  color: var(--blue);
}
@media (max-width: 565px) {
  .review__status {
    font-size: 16px;
  }
}
.review__text {
  margin-top: 20px;
}
.review__visible p {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.review__visible.is-active p {
  -webkit-line-clamp: initial;
  overflow: visible;
}
.review__hidden {
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
}
.review__hidden p {
  margin-top: 10px;
}
.review__toggle {
  margin-top: 20px;
  color: var(--red);
  text-decoration: underline;
}

.faq {
  padding-bottom: 55px;
}
.faq__title {
  text-align: center;
}

.accordeon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 30px;
  margin-top: 25px;
}
@media (max-width: 768px) {
  .accordeon {
    grid-template-columns: 1fr;
  }
}
.accordeon__header {
  position: relative;
  z-index: 1;
  height: 64px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 15px;
  cursor: pointer;
  overflow: hidden;
}
@media (max-width: 565px) {
  .accordeon__header {
    font-size: 14px;
  }
}
.accordeon__header svg {
  flex-shrink: 0;
  transition: all 0.3s;
}
.accordeon__item {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.accordeon__item:not(:nth-of-type(1)) {
  margin-top: 15px;
}
.accordeon__item.opened .accordeon__header svg {
  transform: rotate(180deg);
}
.accordeon__item.opened .accordeon__header svg path {
  fill: var(--red);
}
.accordeon__item:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: -36px;
  top: -40px;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background: #F6FBFF;
}
.accordeon__content p {
  margin-top: 15px;
  padding-left: 25px;
  padding-bottom: 30px;
}

.contacts {
  margin-top: 130px;
}
@media (max-width: 565px) {
  .contacts {
    margin-top: 0;
  }
}
.contacts__box {
  position: relative;
  padding-top: 90px;
  padding-bottom: 35px;
}
@media (max-width: 565px) {
  .contacts__box {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.contacts__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 565px) {
  .contacts__map {
    position: static;
    height: 295px;
  }
}
.contacts-data {
  position: relative;
  z-index: 1;
  width: 400px;
  padding: 40px 25px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 565px) {
  .contacts-data {
    width: 100%;
    margin-bottom: -30px;
  }
}
.contacts-data__name {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;
  color: var(--blue);
  line-height: 18px;
}
.contacts-data__phone {
  font-size: 24px;
  font-weight: 700;
  color: var(--grey);
}
.contacts-data__email {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  text-decoration: underline;
}
.contacts-data__timetable {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey);
}
.contacts-social {
  display: flex;
  gap: 15px;
  margin-top: 8px;
}
.contacts-social__icon {
  width: 22px;
}

.popup {
  display: none;
  background: #FFF;
  border-radius: 10px;
}
.popup__title {
  font-size: 20px;
  font-weight: 700;
}
.popup__list {
  margin-top: 13px;
}
.popup-record {
  max-width: 735px;
  width: 100%;
  padding: 0;
}
.popup-record__box {
  display: flex;
  flex-direction: row;
}
.popup-record__img {
  flex-basis: 50%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
@media (max-width: 768px) {
  .popup-record__img {
    display: none;
  }
}
.popup-record__form {
  flex-basis: 50%;
  padding: 30px 25px;
}
@media (max-width: 768px) {
  .popup-record__form {
    flex-basis: 100%;
  }
}
.popup-record__title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #000;
}
.popup-record__tagtitle {
  margin-top: 15px;
  text-align: center;
  color: #000;
}
.popup-thanks {
  max-width: 350px;
  width: 100%;
  padding: 20px;
}
.popup-thanks__img {
  margin: 0 auto;
}
.popup-thanks__title {
  margin-top: 13px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.popup-thanks__tagtitle {
  margin-top: 18px;
  text-align: center;
}
.popup-thanks__button {
  width: 100%;
  margin-top: 30px;
}
.popup-group {
  max-width: 360px;
  width: 100%;
  padding: 30px 25px;
}
.popup-group__text {
  margin-top: 40px;
}
.popup-group__item {
  margin-top: 15px;
}
.popup-group__locname {
  display: block;
}
.popup-group__locroute {
  margin-top: 5px;
  font-size: 12px;
  color: var(--red);
  text-decoration: underline;
}
.popup-timetable {
  max-width: 720px;
  width: 100%;
  padding: 30px;
}
@media (max-width: 768px) {
  .popup-timetable {
    padding: 10px;
  }
}
.popup-timetable__title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.popup-timetable__object:not(:nth-of-type(1)) {
  margin-top: 60px;
}
.popup-timetable__objectname {
  margin-top: 4px;
  font-weight: 700;
  color: var(--blue);
  text-align: center;
}
.popup-timetable__status {
  margin-top: 20px;
  text-align: center;
}
.popup-timetable__table {
  margin-top: 15px;
}
.popup-timetable__info {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 700;
}
.popup-timetable__price {
  margin-top: 40px;
}
.popup-timetable__pricetext {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  color: var(--blue);
}
.popup-timetable__priceitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.popup-timetable__priceitem:not(:nth-of-type(1)) {
  margin-top: 15px;
}
.popup-timetable__priceval {
  font-size: 18px;
  font-weight: 700;
}
.popup-timetable__record {
  font-size: 14px;
  font-weight: 700;
  color: var(--red);
  text-decoration: underline;
}